import { toDate } from '@/utils/date'
import filters from '@/utils/filters'
import { TDATO_TDATO_SISTEMA } from '@/utils/consts'


export default {
  async update (Vue, id, formData) {
    let valor = formData.valor
    if (TDATO_TDATO_SISTEMA.tiposDate.includes(formData.tipo)) {
      valor = filters.shortDate(toDate(valor))
    }
    await Vue.$api.call(
      'datoSistema.update',
      {
        values: {
          iddato_sistema: id,
          idtdato_sistema: formData.tdato_sistema.idtdato_sistema,
          valor: valor,
        },
      },
    )
  },
}
